import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import useElementResize from '../../../hooks/useElementResize'

export default function VerticalReveal({
  children,
  open,
  delay = 600,
  duration = 800,
  align = 'top',
  className,
  style,
}) {
  const [height, setHeight] = useState(0)
  const { height: elementHeight, ref: content } = useElementResize()

  useEffect(() => {
    setHeight(open ? elementHeight : 0)
  }, [elementHeight, open])

  return (
    <div
      className={classnames(styles.Container, 'w-full relative overflow-hidden', className)}
      style={{
        height,
        transitionDelay: `${delay}ms`,
        transitionDuration: `${duration}ms`,
        ...style,
      }}
    >
      <div
        ref={content}
        className={classnames(
          'absolute left-0 w-full',
          align === 'top' ? 'top-0' : null,
          align === 'bottom' ? 'bottom-0' : null
        )}
      >
        {children}
      </div>
    </div>
  )
}
