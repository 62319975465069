import { get } from 'lodash'

export const sortByKey = (arr, key, descending = false) => {
  return arr.sort((a, b) => {
    let res = 0
    if (get(a, key) > get(b, key)) {
      res = 1
    }

    if (get(a, key) < get(b, key)) {
      res = -1
    }

    if (descending) {
      res = res * -1
    }

    return res
  })
}
