import React from 'react'
import classnames from 'classnames'

export default function DesktopWrapper({ open, children, align }) {
  return open ? (
    <div
      className={classnames(
        'absolute inline-block w-auto h-full bottom-0 z-30 max-w-none whitespace-no-wrap transform translate-y-full pt-1',
        align === 'left' ? 'left-0' : '',
        align === 'right' ? 'right-0' : ''
      )}
    >
      {children}
    </div>
  ) : null
}
