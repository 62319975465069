import { useCallback, useState } from 'react'

export default function useRequest(promise) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const exec = useCallback(
    async (...args) => {
      setError(null)
      setLoading(true)

      try {
        return (await promise(...args)) || 'ok'
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }

      return null
    },
    [promise]
  )

  const execWithCallback = useCallback(
    (...args) => async (callback) => {
      setError(null)
      setLoading(true)

      try {
        const result = await promise(...args)
        callback && (await callback(result || 'ok'))
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }

      return null
    },
    [promise]
  )

  return { exec, execWithCallback, loading, error }
}
