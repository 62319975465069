import React from 'react'
import Link from 'next/link'
import { useStore } from '../../../providers/store/context'

export default function WebViewCompatibleLink({
  children,
  rel,
  type = 'internal',
  onClick,
  href,
  className,
  style,
  target,
  ...rest
}) {
  const { store } = useStore()

  const handleClick = (e) => {
    if (store.mode === 'webview') {
      e.preventDefault()
      window.ReactNativeWebView?.postMessage?.(JSON.stringify({ type, href }))
    }
    onClick && onClick(e)
  }

  if (type === 'internal') {
    return (
      <Link href={href} passHref {...rest}>
        <a onClick={handleClick} className={className} style={style} rel={rel}>
          {children}
        </a>
      </Link>
    )
  } else {
    return (
      <a href={href} target={target} onClick={handleClick} className={className} style={style} rel={rel} {...rest}>
        {children}
      </a>
    )
  }
}
