import React from 'react'
import * as Icon from 'react-icons/fi'
import classnames from 'classnames'
import Link from 'next/link'
import { useFloatingCard } from '../../context'

export default function Button({
  children,
  asComponent,
  asLink,
  help,
  icon,
  onClick,
  renderCustomIcon,
  linkProps,
  ...rest
}) {
  const { handleClick } = useFloatingCard()
  const Element = asLink ? 'a' : asComponent || 'button'
  const hasIcon = renderCustomIcon || icon

  const getIcon = () => {
    try {
      const Element = Icon['Fi' + icon]
      return Element ? <Element /> : null
    } catch {
      return null
    }
  }

  const content = (
    <Element
      className={classnames(
        'relative block focus:outline-none text-xs sm:text-sm px-8 py-3 w-full hover:bg-gray-100 active:bg-gray-200'
      )}
      onClick={handleClick(onClick)}
      {...rest}
    >
      <div className="flex items-center text-gray-800 leading-none hover:text-black">
        {hasIcon ? (
          <div className="w-6">
            <span className="text-lg">{renderCustomIcon ? renderCustomIcon() : getIcon()}</span>
          </div>
        ) : null}
        <div className={classnames('flex-1 text-left', hasIcon ? 'ml-1' : null)}>
          <span>{children}</span>
          {help ? <small className="block text-gray-600 text-xs">{help}</small> : null}
        </div>
      </div>
    </Element>
  )

  return asLink ? <Link {...linkProps}>{content}</Link> : content
}
