import { useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useElementResize() {
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)

  const ref = useRef(null)

  useEffect(() => {
    const handleRefResize = () => {
      setWidth(ref.current?.clientWidth)
      setHeight(ref.current?.clientHeight)
    }

    let observer = null
    if (ref.current) {
      observer = new ResizeObserver(handleRefResize)
      observer.observe(ref.current)
    }

    handleRefResize()

    return () => {
      if (observer) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref])

  return { ref, width, height }
}
