import { useLayoutEffect, useState } from 'react'
import { throttle } from 'lodash'

export default function useWindowResize() {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const handleWindowResize = throttle(() => {
      setWidth(document.body.clientWidth)
      setHeight(document.body.clientHeight)
    }, 100)

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return { width, height }
}
