import Service from './Service'

export default class GroupsService extends Service {
  createGroup = async (values) => {
    return await this._post('/groups', values)
  }

  updateGroup = async (uuid, values) => {
    return await this._put(`/groups/${uuid}`, values)
  }

  deleteGroup = async (uuid) => {
    return await this._delete(`/groups/${uuid}`)
  }

  inviteFollowerToGroup = async (groupUUID, usersUUID) => {
    return await this._post(`/groups/${groupUUID}/invite`, { InvitedUUIDs: usersUUID })
  }

  acceptInvitationToGroup = async (invitationUUID, status) => {
    return await this._post(`/groups/change-invitation-status`, { InvitationUUID: invitationUUID, Status: status })
  }

  inviteByEmailToGroup = async (uuid, email, message) => {
    return await this._post(`/groups/${uuid}/invite-by-email`, { InvitedEmail: email, Message: message })
  }

  findAllAdminGroups = async (page = 0, limit = 5) => {
    return await this._get(`/groups/my-groups?Role=admin&include=Role&offset=${page * limit}&limit=${limit}`)
  }

  findAllMemberGroups = async (page = 0, limit = 5) => {
    return await this._get(`/groups/my-groups?Role=member&include=Role&offset=${page * limit}&limit=${limit}`)
  }

  findGroupCount = async () => {
    return await this._get(`/groups/my-groups?offset=0&limit=0`)
  }

  findAllGroupsByRoleAndPage = async (page, member) => {
    return await this._get(`/groups/my-groups?Role=${member}&offset=${page * 5}&limit=5`)
  }

  findAllFollowersMembersByGroup = async (page = 0, uuid) => {
    return await this._get(`/groups/members/follow?GroupUUID=${uuid}&offset=${page * 5}&limit=5&include=User`)
  }

  findAllNonFollowersMembersByGroup = async (page = 0, uuid) => {
    return await this._get(`/groups/members/not-follow?GroupUUID=${uuid}&offset=${page * 5}&limit=5&include=User`)
  }

  findAllTopicsByGroupAndPage = async (page = 0, uuid) => {
    return await this._get(`/posts/group/trending/hashtags?GroupUUID=${uuid}&offset=${page * 10}&limit=10`)
  }

  findAllPostsByTopicAndGroup = async (page = 0, uuid, tagName) => {
    return await this._get(`/posts/by-tag/${tagName}?GroupUUID=${uuid}&offset=${page * 10}&limit=10`)
  }

  findAllInterestedGroups = async (page = 0) => {
    return await this._get(`/groups/interest?offset=${page * 5}&limit=5`)
  }

  findJoinRequestByGroupAndUserUUID = async (groupUUID, userUUID) => {
    return await this._get(`/groups/${groupUUID}/join-requests?MemberRequestUUID=${userUUID}&Status=pending`)
  }

  findGroup = async (uuid) => {
    return await this._get(`/groups/${uuid}?include=Admin,TotalMembers,Role,MembersPreview,RequestStatus,TotalPost`)
  }

  joinGroup = async (uuid) => {
    return await this._post(`/groups/${uuid}/join`)
  }

  leaveGroup = async (uuid) => {
    return await this._post(`/groups/${uuid}/leave`)
  }

  deleteMember = async (uuid) => {
    return await this._delete(`/groups/members/${uuid}`)
  }

  confirmMemberRequest = async (uuid, requestUUID) => {
    return await this._post(`/groups/${uuid}/handle-join-request`, {
      JoinRequestUUID: requestUUID,
      Status: 'confirmed',
    })
  }
  deleteMemberRequest = async (uuid) => {
    return await this._delete(`/groups/join-request/${uuid}`)
  }

  findAllJoinRequestByGroup = async (page, uuid) => {
    return await this._get(`/groups/${uuid}/join-requests?include=User&offset=${page * 5}&limit=5&Status=pending`)
  }

  countAllJoinRequestByGroup = async (uuid) => {
    return await this._get(`/groups/${uuid}/join-requests?offset=0&limit=0&Status=pending`)
  }

  findAllPostByGroup = async (page = 0, groupUUID) => {
    return await this._get(
      `/posts/group-feed?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&ApprovalStatus=approved`
    )
  }

  findAllPendingPosts = async (page = 0, groupUUID) => {
    return await this._get(
      `/posts/group-feed?order=CreatedAt&offset=${page * 5}&limit=5&GroupUUID=${groupUUID}&ApprovalStatus=pending`
    )
  }
  findAllFilesPostByGroup = async (page = 0, groupUUID) => {
    return await this._get(
      `/posts/group-feed?order=-Score,CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&Metadata.Type=file&ApprovalStatus=approved`
    )
  }

  findAllImagesPostByGroup = async (page = 0, groupUUID) => {
    return await this._get(
      `/posts/group-feed?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&GroupUUID=${groupUUID}&Metadata.Type=image&ApprovalStatus=approved`
    )
  }

  findPostsCountByGroup = async (uuid) => {
    return await this._get(`posts/group-feed?GroupUUID=${uuid}&offset=0&limit=0`)
  }

  findAllGroupPostByUser = async (page = 0) => {
    return await this._get(
      `/posts/my-groups-feed?order=-Score,-CreatedAt&include=LikeCount,Group,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&ApprovalStatus=approved`
    )
  }

  searchGroupsByQuery = async (query) => {
    return await this._get(`/groups/search?Query=${query}&offset=0&limit=10&include=Admin`)
  }
}
