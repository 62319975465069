export const toAvatarString = (string) => {
  const arrayString = string.split(' ')
  if (arrayString.length > 1) {
    return `${arrayString[0].slice(0, 1)}${arrayString[1].slice(0, 1)}`.toUpperCase()
  } else {
    return string.slice(0, 2).toUpperCase()
  }
}

export const withHttps = (url) => {
  return !/^https?:\/\//i.test(url) ? `https://${url}` : url
}

export const removeHTTP = (url) => {
  return url.replace(/^https?:\/\//i, '')
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const htmlToPlainText = (html) => {
  var tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

/**
 * Find and replace substrings defined in the from object.
 * @param {string} text - The text to parse
 * @param {array} from - Array of objects that define the positions of each substring.
 * @return {object} A JSX object containing an array of alternating strings and JSX
 *
 * From array structure:
 * [
 *  {
 *    Start: number,
 *    End: number,
 *    renderComponent: (item, value) => any
 *  }
 * ]
 */
export const replaceSubStrings = (text, from) => {
  if (!from) {
    return text
  }

  var parts = []
  let leftIndex = 0

  // Split the whole text. Leave substrings alone.
  for (let substring of from) {
    const leftSide = text.substring(leftIndex, substring.Start - 1)
    const target = text.substring(substring.Start - 1, substring.End)
    leftIndex = substring.End

    if (leftSide?.length > 0) {
      parts.push(leftSide)
    }

    if (target?.length > 0) {
      parts.push(substring.renderComponent(target, substring))
    }
  }

  // Push the rest of the text (if any) to the parts array.
  if (leftIndex <= text.length && text.substring(leftIndex, text.length)) {
    parts.push(text.substring(leftIndex, text.length))
  }

  if (typeof parts[0] === 'string') {
    parts[0] = parts[0].trimStart()
  }

  if (typeof parts[parts.length - 1] === 'string') {
    parts[parts.length - 1] = parts[parts.length - 1].trimEnd()
  }

  return parts
}
