import React from 'react'
import Modal from '../../../Modal'

export default function MobileWrapper({ open, onClose, children }) {
  return (
    <Modal open={open} onClose={onClose} zIndex={9999}>
      <div className="w-full h-full flex items-center justify-center p-3">{children}</div>
    </Modal>
  )
}
