import React from 'react'
import classnames from 'classnames'

export default function Card({ children, className }) {
  return (
    <div
      className={classnames(
        'bg-white rounded-xl shadow-minex overflow-hidden fancy-bounce py-2 relative z-30',
        className
      )}
    >
      {children}
    </div>
  )
}
