import { parseToHsl, toColorString } from 'polished'

function hashCode(str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

export const stringToColor = (string) => {
  return '#' + intToRGB(hashCode(string))
}

export const stringToLightColor = (string) => {
  const hexColor = stringToColor(string)
  const hsl = parseToHsl(hexColor)
  hsl.saturation = 0.5
  hsl.lightness = 0.65
  return toColorString(hsl)
}

export const rgbToHex = (r, g, b) =>
  '#' +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    })
    .join('')
