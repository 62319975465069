import React from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'

export default function Circle({ children, color, image, useDefaultImage = false }) {
  return (
    <div
      className="inline-flex items-center justify-center overflow-hidden relative w-full h-full"
      style={{
        backgroundColor: color,
      }}
    >
      {children}
      {image ? (
        <div className="absolute w-full h-full top-0 left-0 object-cover bg-gray-200">
          <Image src={image} layout="fill" objectFit="cover" priority unoptimized={true} />
        </div>
      ) : null}

      {useDefaultImage && !image ? (
        <div className="absolute w-full h-full top-0 left-0 object-cover bg-gray-200 rounded-full">
          <Image src={'/static/images/default-user.png'} layout="fill" objectFit="cover" priority unoptimized={true} />
        </div>
      ) : null}
    </div>
  )
}
Circle.defaultProps = {
  size: 'md',
}

Circle.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  image: PropTypes.any,
  reducedImage: PropTypes.any,
  customSize: PropTypes.any,
}
