import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ClickOutsideHandler from '../../generic/ClickOutsideHandler'
import Divider from './components/Divider'
import Content from './components/Content'
import Button from './components/Button'
import useWindowResize from '../../../hooks/useWindowResize'
import DesktopWrapper from './components/DesktopWrapper'
import MobileWrapper from './components/MobileWrapper'
import Card from './components/Card'
import { FloatingCardContext } from './context'

export default function FloatingCard({ children, renderTrigger, align = 'right', clickOutsideClassName, ...rest }) {
  const [open, setOpen] = useState(false)
  const { width } = useWindowResize()

  const handleClick = useCallback(
    (callback) => () => {
      setOpen(false)
      callback && callback()
    },
    []
  )

  const handleTriggerClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <FloatingCardContext.Provider value={{ handleClick }}>
      {width > 1023 ? (
        <ClickOutsideHandler onClickOutside={() => setOpen(false)} className={clickOutsideClassName}>
          <div {...rest}>
            {renderTrigger(handleTriggerClick)}
            <DesktopWrapper open={open} align={align}>
              <Card>{children}</Card>
            </DesktopWrapper>
          </div>
        </ClickOutsideHandler>
      ) : (
        <div {...rest}>
          {renderTrigger(handleTriggerClick)}
          <MobileWrapper open={open} onClose={() => setOpen(false)}>
            <Card className="w-full">{children}</Card>
          </MobileWrapper>
        </div>
      )}
    </FloatingCardContext.Provider>
  )
}

FloatingCard.propTypes = {
  children: PropTypes.any,
  triggerProps: PropTypes.any,
  renderTrigger: PropTypes.any,
  align: PropTypes.oneOf(['left', 'right']),
}

FloatingCard.Divider = Divider
FloatingCard.Content = Content
FloatingCard.Button = Button
