import React from 'react'
import classnames from 'classnames'
import Circle from './components/Circle'
import { stringToLightColor } from '../../../utils/colors'
import { toAvatarString } from '../../../utils/strings'

export default function Avatar({ className, text, image, useDefaultImage = false, roundedClassName, ...rest }) {
  const color = text ? stringToLightColor(text) + '6F' : '#ffffff'

  return (
    <div
      className={classnames(
        'relative overflow-hidden z-transform bg-white',
        className,
        roundedClassName || 'rounded-full'
      )}
      {...rest}
    >
      <Circle color={color} image={image} useDefaultImage={useDefaultImage}>
        {text ? toAvatarString(text) : null}
      </Circle>
    </div>
  )
}
